$(document).foundation();

$(document).ready(function() {
  // utils
  initVerticalCenter();
  inlineSVGs([
    ".footer__social img",
    ".off-canvas__social img",
    ".popup-form__bed-picker + label img",
    ".floorplan__room img",
    ".activity-planner__types img",
    ".property-card__select img",
    ".rentals__properties-table__remove img",
    ".rentals__properties-table__select img"
  ]);

  // global
  initSliders();
  initUsefulInfo();
  initForms();
  initSiteNotification();

  // template specific
  initFloorplans();
  initActivityPlanner();
  initMyProfile();
  initRentals();
  initOffersGrid();
  initEventsCalendar();
  openCalendarInfo();
  moreButtonClicked();
});

function initVerticalCenter() {
  $(".vertical-center").each(function() {
    $(this).wrapInner(
      '<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>'
    );
  });
}

// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
  var selectorString = "";
  if (typeof inputValue === "object") {
    selectorString = inputValue.join(",");
  } else {
    selectorString = inputValue;
  }

  $(selectorString).each(function() {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    $.get(
      imgURL,
      function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find("svg");

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr("xmlns:a");

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }

        // Replace image with new SVG
        $img.replaceWith($svg);
      },
      "xml"
    );
  });
}

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
  if (typeof scaleToParent === "undefined") {
    scaleToParent = false;
  }

  var viewportWidth, viewportHeight;
  if (scaleToParent) {
    viewportWidth = $(element)
      .parent()
      .innerWidth();
    viewportHeight = $(element)
      .parent()
      .innerHeight();
  } else {
    viewportWidth = $(window).innerWidth();
    viewportHeight = $(window).innerHeight();
  }

  var $element = $(element);

  var elementWidth = $element.attr("width");
  var elementHeight = $element.attr("height");

  // Aspect ratio = height / width
  var viewportRatio = viewportHeight / viewportWidth;
  var videoRatio = elementHeight / elementWidth;

  //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);

  var newWidth = (elementWidth / elementHeight) * viewportHeight;
  var newHeight = (elementHeight / elementWidth) * viewportWidth;
  var newTop = 0;

  if (viewportRatio > videoRatio) {
    // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
    //console.log('crop left and right');
    $element.css("height", viewportHeight);
    $element.css("width", newWidth);
    $element.css("left", "-" + (newWidth - viewportWidth) / 2 + "px");
    $element.css("top", "0");
  } else if (viewportRatio < videoRatio) {
    // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
    //console.log('crop top and bottom');
    $element.css("width", viewportWidth);
    $element.css("height", newHeight);
    $element.css("left", "0");
    $element.css("top", "-" + (newHeight - viewportHeight) / 2 + "px");
  } else {
    // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
    //console.log('no crop');
    $element.css("height", viewportHeight);
    $element.css("width", viewportWidth);
    $element.css("left", "0");
    $element.css("top", "0");
  }
}

function initUsefulInfo() {
  // Change useful info into an accordion for <= tablet
  $(".useful-info h3").on("click", function() {
    if ($(window).innerWidth() <= 1024) {
      $(this).toggleClass("open");
      $(".useful-info ul").slideToggle();

      $(window).on("resize", function() {
        if ($(window).innerWidth() > 1024) {
          $(".useful-info ul").slideDown();
        }
      });
    }
  });
}

function initSliders() {
  $(".login__slider").slick({
    fade: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 2000
  });

  $(".promo-carousel__inner").slick({
    fade: true,
    arrows: true,
    dots: true
  });

  $(".slider").slick({
    fade: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 2000
  });
}

function initForms() {
  // include hidden fields in validation
  var validator = $(".umbraco-forms-form form").data("validator");

  if (validator) {
    validator.settings.ignore = "";
    var originalErrorPlacement = validator.settings.errorPlacement;
    validator.settings.errorPlacement = function(error, element) {
      if (error.text().length > 0) {
        // show when accordion is containing a validation error
        element
          .closest(".accordionContent")
          .prev(".accordionButton")
          .addClass("error");

        // show when popup is containing a validation error
        var floorplanForm = element.closest(".floorplan__form");

        if (floorplanForm.length > 0) {
          $(
            '.floorplan__room[data-open="' + floorplanForm.attr("id") + '"'
          ).addClass("floorplan__room--error");
        }

        // show when tab is containing a validation error
        var tab = element.closest(".tabs-panel");

        if (tab) {
          $('a[href="#' + tab.attr("id") + '"]')
            .parent()
            .addClass("error");
        }

        // show message next to submit button
        element
          .closest("form")
          .find(".form-validation-summary")
          .html(
            "Some fields (highlighted in red) are required or not in the right format."
          );
      }

      // call default function
      originalErrorPlacement(error, element);
    };
  }

  $(".umbraco-forms-form form")
    .find("input, textarea, select")
    .on("focusout keyup", function() {
      // check if all fields in current section are valid and remove error class
      var element = $(this);

      var accordionContent = element.closest(".accordionContent");

      if (accordionContent.length > 0) {
        var hasInvalidFields =
          accordionContent.find(".input-validation-error").length > 0;

        if (!hasInvalidFields) {
          accordionContent.prev(".accordionButton").removeClass("error");
        }
      }

      var floorplanForm = element.closest(".floorplan__form");

      if (floorplanForm.length > 0) {
        var hasInvalidFields =
          floorplanForm.find(".input-validation-error").length > 0;

        if (!hasInvalidFields) {
          $(
            '.floorplan__room[data-open="' + floorplanForm.attr("id") + '"'
          ).removeClass("floorplan__room--error");
        }
      }

      var tab = element.closest(".tabs-panel");

      if (tab) {
        var hasInvalidFields = tab.find(".input-validation-error").length > 0;

        if (!hasInvalidFields) {
          $('a[href="#' + tab.attr("id") + '"]')
            .parent()
            .removeClass("error");
        }
      }

      // if the form has no more errors, also remove the validation summary warning
      var form = element.closest("form");
      var hasInvalidFields = form.find(".input-validation-error").length > 0;

      if (!hasInvalidFields) {
        form.find(".form-validation-summary").html("");
      }
    });

  $(".validate-this").validate();

  $(".form select").selectric();

  $(".form__accordion, .popup-form__accordion, .accordion-form").accordiom({
    showFirstItem: false
  });

  $(".quantity-up").on("click", function() {
    var input = $(this).next("input");
    var val = parseInt(input.val());

    if (isNaN(val)) {
      input.val("1").trigger("change");
      return;
    }

    val += 1;

    // test new value against field pattern
    var pattern = input.attr("pattern");

    var field = $(this)
      .parent()
      .data("field");
    var isChildren = field === "children";

    if (isChildren && pattern === "[0-2]{1}") {
      // when the number of adults is 2, the number of children is limited to 1
      var adults = $(this)
        .closest(".grid-x")
        .find('.popup-form__quantity[data-field="adults"] input')
        .val();

      if (adults === "2") {
        pattern = "[0-1]{1}";
      }
    }

    var isAdults = field === "adults";

    if (isAdults && val === 2) {
      // ensure that children is set to a maximum of 1
      var childrensField = $(this)
        .closest(".grid-x")
        .find('.popup-form__quantity[data-field="children"] input');

      if (childrensField.val() === "2") {
        childrensField.val(1);
      }
    }

    var regex = new RegExp("^" + pattern + "$");

    if (regex.test(val)) {
      // matches pattern, so change it
      input.val(val).trigger("change");
    }

    return false;
  });

  $(".quantity-down").on("click", function() {
    var input = $(this).prev("input");
    var val = parseInt(input.val());

    if (isNaN(val)) {
      return;
    }

    val -= 1;

    // test new value against field pattern
    var pattern = input.attr("pattern");
    var regex = new RegExp("^" + pattern + "$");

    if (regex.test(val)) {
      // matches pattern, so change it
      input.val(val).trigger("change");
    }

    return false;
  });

  $("button[data-next-tab]").on("click", function() {
    // go to next tab panel
    var nextPanel = $(this)
      .closest(".tabs-panel")
      .next(".tabs-panel");
    var tabs = nextPanel.closest(".tabs-content").prev(".tabs");

    tabs.foundation("selectTab", nextPanel);
  });

  $(".form__person-more").on("click", function() {
    // toggle description
    var $btn = $(this);

    $btn
      .toggleClass("on")
      .text($btn.data($btn.hasClass("on") ? "less" : "more"))
      .closest(".form__person-content")
      .find(".form__person-description")
      .toggleClass("form__person-description--expanded");
  });
}

function initFloorplans() {
  $(".floorplan__form.popup-form")
    .on("closed.zf.reveal", function() {
      var popup = $(this);
      var popupId = popup.attr("id");

      // check if current form misses any required values
      var hasRequiredValues =
        popup
          .find("input, textarea, select")
          .filter(function(i, el) {
            // only get list of required fields
            var $el = $(el);

            return (
              $el.attr("data-val-required") || $el.attr("data-val-requiredlist")
            );
          })
          .filter(function(i, el) {
            // check if field has no value
            var $el = $(el);

            if ($el.attr("type") === "checkbox") {
              return !$el.is(":checked");
            }

            if ($el.attr("type") === "radio") {
              return (
                $(
                  'input[type="radio"][name="' + $el.attr("name") + '"]:checked'
                ).length === 0
              );
            }

            var value = $el.val();

            return value.length === 0;
          }).length === 0;

      $('.floorplan__room[data-open="' + popupId + '"]').toggleClass(
        "floorplan__room--complete",
        hasRequiredValues
      );

      if (hasRequiredValues) {
        $('.floorplan__room[data-open="' + popupId + '"]').removeClass(
          "floorplan__room--error"
        );
      }

      // check if submit needs to be disabled (when not all rooms have been completed)
      var submitBtn = $(".floorplan")
        .closest("form")
        .find('button[type="submit"][value="Submit"]');

      if (
        $("button.floorplan__room:not(.floorplan__room--complete)").length > 0
      ) {
        submitBtn.attr("disabled", "disabled");
      } else {
        submitBtn.removeAttr("disabled");
      }
    })
    .trigger("closed.zf.reveal");
}

function initActivityPlanner() {
  $('input[name="activity-type"]').on("change", function() {
    // trigger ajax call to load form
    $(this)
      .siblings("a[data-ajax]")
      .trigger("click");

    // show form panel
    $(".activity-planner__details").slideUp();
    $(".activity-planner__details--" + $(this).val()).slideDown();
  });

  $(".activity-planner__add-event").on("click", function() {
    // set selected date in activity forms
    var date = $(this).data("date");
    $("#create-event").data("date", date);
  });

  $("#create-event").on("closed.zf.reveal", function() {
    // reset activity selector
    $('input[name="activity-type"]').prop("checked", false);
    $(".activity-planner__details").slideUp();
  });
}

function onActivityFormLoaded() {
  // initialise the dropdowns of the loaded part
  var updateElementId = $(this).data("ajax-update");
  $(updateElementId)
    .find("select")
    .selectric();

  // set selected date
  var date = $("#create-event").data("date");
  $(updateElementId)
    .find('input[data-alias="date"]')
    .val(date);

  // set up the validators (copied from Umbraco Forms JS)
  $.validator.unobtrusive.parse($(updateElementId).find("form"));
}

function onActivityFormBeginSubmit() {
  // disable form submit button
  $("#create-event .activity-planner__details:visible .form").addClass(
    "disabled"
  );
}

function onActivityFormSubmitComplete() {
  // enable button again
  $("#create-event .activity-planner__details:visible .form").removeClass(
    "disabled"
  );
}

function onActivityFormSubmitted(data) {
  // add activity to calendar grid
  var dateCell = $(
    '.activity-planner__add-event[data-date="' + data.Date + '"]'
  )
    .first()
    .parent();

  dateCell.html(
    '<div class="activity-planner__event"><h2>' +
      data.Activity +
      "</h2><p>" +
      data.Time +
      "</p></div>"
  );

  // add it to the list of selected activities
  var selectedActivities = $('.activity-planner input[data-alias="activities"]')
    .val()
    .split(",")
    .filter(function(i) {
      return i;
    });

  if (selectedActivities.indexOf(data.RecordId) < 0) {
    selectedActivities.push(data.RecordId);
  }

  $('.activity-planner input[data-alias="activities"]').val(
    selectedActivities.join(",")
  );

  // trigger a save of the planner form
  $('.activity-planner button[name="__prev"]').trigger("click");

  // close dialog
  $("#create-event").foundation("close");
}

function onActivityPlannerFormBeginSubmit() {
  // disable form
  $(".activity-planner")
    .closest(".form")
    .addClass("disabled");
}

function onActivityPlannerFormSubmitComplete() {
  // enable form again
  $(".activity-planner")
    .closest(".form")
    .removeClass("disabled");
}

function onActivityPlannerFormSubmitted(data, status, xhr) {
  if (data.RecordId) {
    // update record ID on form for future saves/submits
    $(".activity-planner")
      .closest("form")
      .find('input[name="RecordId"]')
      .val(data.RecordId);
  } else if (data.Submitted === true) {
    // keep form disabled during reload
    $(".activity-planner")
      .closest(".form")
      .addClass("disabled");

    if (data.RedirectUrl && data.RedirectUrl.length > 0) {
      // redirect to specific page
      window.location.href = data.RedirectUrl;
    } else {
      // reload the current page
      window.location.reload(true);
    }
  }
}

function initMyProfile() {
  $(".profile__section h2").on("click", function() {
    if ($(window).innerWidth() < 640) {
      if (
        $(this)
          .parent()
          .hasClass("profile__section--open")
      ) {
        $(this)
          .parent()
          .find(".profile__details")
          .slideUp();
        $(this)
          .parent()
          .removeClass("profile__section--open");
      } else {
        $(".profile__details").slideUp();
        $(".profile__section").removeClass("profile__section--open");
        $(this)
          .parent()
          .find(".profile__details")
          .slideDown();
        $(this)
          .parent()
          .addClass("profile__section--open");
      }

      $(window).on("resize", function() {
        if ($(window).innerWidth() >= 640) {
          $(".profile__details").slideDown();
          $(".profile__section").removeClass("profile__section--open");
        }
      });
    }
  });
}

function initRentals() {
  $(".property-card__select").on("click", function() {
    var recordId = $(this).val();
    var propertyCard = $(this).parent();
    var isSelected = propertyCard.hasClass("property-card--selected");
    var url = $(this)
      .closest(".rentals__grid")
      .data("update-url");
    var sessionKey = $(this)
      .closest(".rentals__grid")
      .data("session-key");

    // save (de-)selection in session
    $.get(
      url,
      { recordId: recordId, sessionKey: sessionKey, remove: isSelected },
      function() {
        updateSelectedProperties(recordId, propertyCard, isSelected);
      }
    );
  });

  $(".rentals__form").on(
    "click",
    ".rentals__properties-table__remove",
    function() {
      var recordId = $(this)
        .prev('input[type="checkbox"]')
        .val();
      var propertyCard = $(
        '.property-card__select[value="' + recordId + '"]'
      ).parent();
      var url = $(".rentals__grid").data("update-url");
      var sessionKey = $(".rentals__grid").data("session-key");

      // save de-selection in session
      $.get(
        url,
        { recordId: recordId, sessionKey: sessionKey, remove: true },
        function() {
          updateSelectedProperties(recordId, propertyCard, true);
        }
      );
    }
  );

  function updateSelectedProperties(recordId, propertyCard, isRemove) {
    if (isRemove) {
      // remove the row from the table
      $(
        '.rentals__properties-table--selected tr[data-record-id="' +
          recordId +
          '"]'
      ).remove();
    } else {
      // add new row to the table by cloning the template row
      var row = $(".rentals__properties-table--selected tr.template").clone();

      row.removeClass("template");

      // add record display values
      var propertyName = propertyCard.data("property-name");
      var weekNumber = propertyCard.data("weeknumber");
      var bedrooms = propertyCard.data("bedrooms");

      row.find("h4").text(propertyName);
      row.find("h4 + p").text("week " + weekNumber);
      row
        .find(".bedrooms-")
        .removeClass("bedrooms-")
        .addClass("bedrooms-" + bedrooms)
        .text(bedrooms);
      row.find('input[type="checkbox"]').attr("checked", true);

      // add in correct record ID
      var html = row[0].outerHTML.replace(
        /00000000-0000-0000-0000-000000000000/g,
        recordId
      );

      $(".rentals__properties-table--selected").append(html);
    }

    // update styles
    propertyCard.toggleClass("property-card--selected", !isRemove);
  }

  // Build up the filter menu for mobile from the desktop select elements
  var filtersHTML =
    '<ul class="vertical menu" data-drilldown><li><a>Filter</a></li>';
  $(".rentals__filters select").each(function() {
    filtersHTML += "<li>";

    var c = 0;
    $(this)
      .find("option")
      .each(function() {
        if (c === 0) {
          filtersHTML += '<a href="#">' + $(this).text() + "</a>";
          filtersHTML += '<ul class="menu">';
        } else {
          filtersHTML += '<li><a href="#">' + $(this).text() + "</a></li>";
        }
        c++;
      });
    filtersHTML += "</ul>";
    filtersHTML += "</li>";
  });
  filtersHTML += "</ul>";

  $(".off-canvas-filters__inner").append(filtersHTML);
  $(".off-canvas-filters__inner ul").foundation();
}

function initOffersGrid() {
  $(".offers-grid__offers").masonry({
    columnWidth: ".offers-grid__item",
    itemSelector: ".offers-grid__item",
    percentPosition: true,
    horizontalOrder: true
  });
}

function initEventsCalendar() {
  if ($(".events-calendar #calendar").length === 0) return;

  var calendarEl = document.getElementById("calendar");

  var calendar = new FullCalendar.Calendar(calendarEl, {
    //var calendar = $('#calendar').fullCalendar({
    plugins: ["dayGrid", "list"],
    columnHeaderText: function(date) {
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ];
      return days[date.getDay()];
    },
    aspectRatio: 1.25,
    editable: false,
    noEventsMessage: "No events to display for this month",
    eventLimit: 5, // allow "more" link when too many events
    views: {
      dayGridMonth: {
        // name of view
        // other view-specific options here
      }
    },
    defaultDate: startDate,
    events: eventsCalendarItems, // grab the JSON list from the page source variable "eventsCalendarItems"
    eventOrder: "sortOrder,start,-duration,allDay,title"
  });

  calendar.render();

  updateMonthYearHeading();
  onlyShowListIfMobile();
  $(window).on("resize", onlyShowListIfMobile);

  // Toggle between the month and list views
  var monthViewButton = $("#month-view");
  var listViewButton = $("#list-view");

  monthViewButton.click(function() {
    calendar.changeView("dayGridMonth");
    $("input[name=view]").val("month");
    monthViewButton.addClass("selected");
    listViewButton.removeClass("selected");
    moreButtonClicked();
    setTimeout(function() {
      openCalendarInfo();
    }, 500);
  });

  listViewButton.click(function() {
    calendar.changeView("listMonth");
    $("input[name=view]").val("listMonth");
    listViewButton.addClass("selected");
    monthViewButton.removeClass("selected");
    moreButtonClicked();
    setTimeout(function() {
      openCalendarInfo();
    }, 500);
  });

  // Next and previous month navigation
  $(".events-calendar__month-prev").on("click", function() {
    $(".fc-prev-button").trigger("click");
    updateMonthYearHeading();
    moreButtonClicked();
    setTimeout(function() {
      openCalendarInfo();
    }, 500);
  });
  $(".events-calendar__month-next").on("click", function() {
    $(".fc-next-button").trigger("click");
    updateMonthYearHeading();
    moreButtonClicked();
    setTimeout(function() {
      openCalendarInfo();
    }, 500);
  });

  // Take the month and year from the calendar heading and put it above the calendar
  function updateMonthYearHeading() {
    var currentdate = $(".fc-left h2")
      .text()
      .split(" ");
    $(".events-calendar__month-text").html(
      "<small>" + currentdate[1] + "</small><br />" + currentdate[0]
    );
  }

  // Mobile should only show the list view
  function onlyShowListIfMobile() {
    if (window.innerWidth <= 640) {
      $(".events-calendar__set-view").hide();
      calendar.changeView("listMonth");
      $("input[name=view]").val("listMonth");
      $("#list-view").addClass("selected");
      $("#month-view").removeClass("selected");
    } else {
      $(".events-calendar__set-view").show();
    }
  }
}

function openCalendarInfo() {
  $(".fc-event-container a").on("click", function() {
    var $href = $(this).attr("href");
    if ($href.indexOf("calendar-info") > -1) {
      $($href).foundation("open");
    }
  });

  $(".fc-list-item-title a").on("click", function() {
    var $href = $(this).attr("href");
    if ($href.indexOf("calendar-info") > -1) {
      $($href).foundation("open");
    }
  });
}

// More button clicked
function moreButtonClicked() {
  $(".fc-more").on("click", function() {
    setTimeout(function() {
      openCalendarInfo();
    }, 500);
  });
}

function initSiteNotification() {
  var $siteNotification = $(".site-notification");

  if ($siteNotification.length === 0) {
    return;
  }

  var siteNotificationId = $siteNotification.data("id");
  var siteNotificationCookie = Cookies.get("site-notification");

  if (siteNotificationCookie && siteNotificationCookie === siteNotificationId) {
    // user has already dismissed notification
    return;
  }

  // close notification on click of close button
  $siteNotification.find(".site-notification__close").one("click", function() {
    $siteNotification.removeClass("site-notification--active");

    // save cookie
    Cookies.set("site-notification", siteNotificationId, { expires: 30 });
  });

  // show notification
  $siteNotification.addClass("site-notification--active");
}
